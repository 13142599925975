import { secure, useSecureGet, useSetting } from 'onekijs';
import React, { FC } from 'react';
import ApiTabs from '../../modules/apis/components/ApiTabs';
import { ApiType } from '../../modules/apis/typings/api';
import DefaultError from '../../modules/core/components/DefaultError';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadApis } from '../../modules/core/libs/rbac';


let ListApisPage: FC = () => {
  const baseUrl = useSetting('server.baseUrl');
  const [apiTypes] = useSecureGet<ApiType[]>(`${baseUrl}/open-apis/types`);

  return (
    <PageLayout title="Open Insurance" titleColored="API">
      <>
        <p>
          You can search for the APIS in API Catalog using the API name or description.
        </p>
        <div className="mb-4">
          <ApiTabs apiTypes={apiTypes} />
        </div>
      </>
    </PageLayout>
  );
};
ListApisPage = secure(ListApisPage, canReadApis, { ErrorComponent: DefaultError });
export default ListApisPage;
