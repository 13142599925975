/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
import { asyncGet, secure, useGlobalProp, useSetting } from 'onekijs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RedocRawOptions, RedocStandalone } from 'redoc';
import DefaultError from '../../modules/core/components/DefaultError';
import { canReadDocs } from '../../modules/core/libs/rbac';

// const url  = "http://localhost:8080/open-apis/dev-portal";

const redocOptions: RedocRawOptions = {
  hideDownloadButton: true,
  scrollYOffset: 80,
};

let RedocPage: FC = () => {
  const { id, type } = useParams<{ id: string; type: string }>();
  const baseUrl = useSetting('server.baseUrl');
  const url = useMemo(() => {
    return `${baseUrl}/open-apis/${type}-${id}`;
  }, [id, baseUrl, type]);
  const auth = useGlobalProp('auth');
  // const aRef = useRef(null);

  const [spec, setSpec] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  // const downloadSwagger = useCallback(() => {
  //   asyncGet(url, { auth }).then((data) => {
  //     downloadFile(data, `${id}.json`, 'application/json', aRef.current);
  //   });
  // }, [id, url, auth]);

  useEffect(() => {
    setLoading(true);
    asyncGet(url, { auth })
      .then((data) => {
        setLoading(false);
        setSpec(data);
      })
      .catch((error) => {
        // TODO send notification
        console.log('error', error);
      });
  }, [url, auth]);

  if (loading) return null;
  console.log(spec);

  return (
    <div className="mt-20">
      <RedocStandalone spec={spec} options={redocOptions} />
    </div>
  );
};

// const Select = ({services, onSelect}) => {
//   return (
//     <select onChange={onSelect} style={{width: "200px", height:"40px"}}>
//       {services.map(service => (
//         <option key={service} value={service}>{service}</option>
//       ))}
//     </select>
//   )
// }
RedocPage = secure(RedocPage, canReadDocs, { ErrorComponent: DefaultError });
export default RedocPage;
