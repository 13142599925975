import { isCollectionLoading, useList, useSetting } from 'onekijs';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Api } from '../../core/libs/api';
import ApiListEntry from './ApiListEntry';

type ApiListOptions = {
  type: string;
  className?: string;
};

// const userAdapter: CollectionItemAdapter<Api, ItemMeta> = (data?: Api) => {
//   return {
//     id: data ? data.id : undefined,
//     text: data ? data.name : '',
//     data,
//     meta: {},
//   };
// };

const ApiList: FC<ApiListOptions> = ({ type, className }) => {
  const baseUrl = useSetting('server.baseUrl');
  const filterRef = useRef<HTMLInputElement>(null);
  // const [apis, loading] = useSecureGet<Api[]>(`${baseUrl}/open-apis?t=${type}`);
  const apiList = useList<Api>(`${baseUrl}/open-apis?t=${type}`, {
    auth: true,
    fetchOnce: true,
  });
  const apis = apiList.items;


  const [filter, setFilter] = useState<string>('');

  const onFilter = (value: string) => {
    setFilter(value);
    apiList.filter({
      operator: 'or',
      criterias: [
        {
          field: 'name',
          operator: 'i_like',
          value,
        },
        {
          field: 'description',
          operator: 'i_like',
          value,
        },
      ],
    });
  };

  useEffect(() => {
    if (filterRef.current) {
      filterRef.current.focus();
    }
    
  }, [])

  return (
    <>
      <div className="bg-gray-100 p-2 mt-5 rounded">
        <div className="my-2 p-1 bg-white flex border border-gray-300 rounded">
          <div className="flex flex-auto flex-wrap"></div>
          <input
            ref={filterRef}
            placeholder="Filter APIs"
            name="filter"
            value={filter}
            onChange={(e) => onFilter(e.target.value)}
            type="text"
            autoComplete="off"
            aria-autocomplete="list"
            aria-controls="react-autowhatever-1"
            className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
          />
          <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
            <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-chevron-up w-4 h-4"
              >
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <circle cx="10" cy="10" r="7"></circle>
                <line x1="21" y1="21" x2="15" y2="15"></line>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`bg-white ${className ? ' ' + className : ''}`}>
        {isCollectionLoading(apiList) && <div className="no-api-found">Loading ...</div>}
        {!isCollectionLoading(apiList) && apis?.length === 0 && <div className="no-api-found">No API found</div>}
        {apis && (
          <div className="flex flex-wrap -m-4">
            {apis.map((api) => (
              <ApiListEntry key={api?.id} api={api} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ApiList;
