import { useValue } from 'onekijs';
import React, { FC } from 'react';
import FormInputEntry from '../../../../core/components/form/FormInputEntry';
import FormSection from '../../../../core/components/form/FormSection';
import FormSelectEntry from '../../../../core/components/form/FormSelectEntry';
import FormTextareaEntry from '../../../../core/components/form/FormTextareaEntry';
import ThreeColumns from '../../../../core/components/grid/ThreeColumns';
import TwoColumns from '../../../../core/components/grid/TwoColumns';

const GeneralInfos: FC = () => {
  const serviceName = useValue('serviceName');
  const apiType = useValue('apiType');
  const gitRepository = useValue('gitRepository');
  const gitPrefix = useValue('gitPrefix');
  return (
    <FormSection title="General informations" description="Define the type of project to generate">
      <TwoColumns className="items-center">
        <FormInputEntry
          label="Service name"
          name="serviceName"
          placeholder="myservice"
          help="Must contain only alphanumeric characters and '-'. Examples: party, policy, edocument"
          required={true}
          requiredMessage="Please indicate a service name"
          regex="^[a-z0-9-]{1,50}$"
          regexMessage={'Service name must contain only contains lowercase and dash characters (max 50 characters)'}
        />
        {serviceName && (
          <div>
            <div className="p-3 m-1 float-left bg-gray-100 border-2 border-dashed border-gray-300 rounded">
              <span className="text-blue-400 font-medium">Project name:</span>
              <span className="ml-2 font-medium">
                {serviceName}-{apiType}
              </span>
            </div>
          </div>
        )}
      </TwoColumns>
      <FormTextareaEntry
        label="Service description"
        name="serviceDescription"
        placeholder="No description"
        required={true}
        requiredMessage="Please indicate a service description"
        height="sm"
      />
      <ThreeColumns className="items-center">
        <FormSelectEntry name="gitRepository" label="GIT Repository" required={true} defaultValue="nrbdigital">
          <option value="nrbdigital">Github NRB Digital</option>
        </FormSelectEntry>
        {gitRepository && gitRepository === 'nrbdigital' && (
          <FormSelectEntry name="gitPrefix" label="Prefix" required={true} defaultValue="ethias-oi-">
            <option value="ethias-oi-">ethias-oi-</option>
            <option value="ethias-edge-">ethias-edge-</option>
            <option value="ethias-oi-necs-">ethias-oi-necs-</option>
          </FormSelectEntry>
        )}
        
        {serviceName && (
          <div>
            <div className="p-3 m-1 float-left bg-gray-100 border-2 border-dashed border-gray-300 rounded">
              <span className="text-blue-400 font-medium">Github repository:</span>
              <span className="ml-2 font-medium">
                {gitRepository}/{gitPrefix}{serviceName}-{apiType}
              </span>
            </div>
          </div>
        )}
      </ThreeColumns>
    </FormSection>
  );
};

export default GeneralInfos;
