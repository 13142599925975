/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
import { asyncGet, get, secure, useGlobalProp, useSetting } from 'onekijs';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { downloadFile } from '../../modules/apis/libs/download';
import DefaultError from '../../modules/core/components/DefaultError';
import { canReadDocs } from '../../modules/core/libs/rbac';


// const url  = "http://localhost:8080/open-apis/dev-portal";

let ApiPage: FC = () => {
  const { id, type } = useParams<{ id: string, type: string }>();
  const baseUrl = useSetting('server.baseUrl');
  const url = useMemo(() => {
    return `${baseUrl}/open-apis/${type}-${id}`;
  }, [id, baseUrl, type]);
  const auth = useGlobalProp('auth');
  const aRef = useRef(null);

  const requestInterceptor = useCallback(
    (req) => {
      req.headers.Authorization = 'Bearer ' + get(auth, 'token.access_token');
      return req;
    },
    [auth],
  );

  const downloadSwagger = useCallback(() => {
    asyncGet(url, { auth }).then(data => {
      downloadFile(data, `${id}.json`, 'application/json', aRef.current);
    })
  }, [id, url, auth])

  return (
    <div className="mt-20">
      <a ref={aRef} className="hidden" />
      <button onClick={downloadSwagger} className="float-right px-4 py-2 bg-gray-800 text-gray-200 text-xs font-semibold rounded hover:bg-gray-800">
        <FaDownload className="inline-block" /> <span className="align-middle">Download</span>
      </button>
      <SwaggerUI
        url={url}
        docExpansion="list"
        requestInterceptor={requestInterceptor}
        onComplete={(ui) => {
          ui.preauthorizeApiKey('bearer', get(auth, 'token.access_token'));
        }}
      />

      {/* <SwaggerUI
        url={`${settings.api.baseUrl}/doc/v1/swaggers/${params.service}`}
        docExpansion="list"
        requestInterceptor={requestInterceptor}
      /> */}
    </div>
  );
};

// const Select = ({services, onSelect}) => {
//   return (
//     <select onChange={onSelect} style={{width: "200px", height:"40px"}}>
//       {services.map(service => (
//         <option key={service} value={service}>{service}</option>
//       ))}
//     </select>
//   )
// }
ApiPage = secure(ApiPage, canReadDocs, { ErrorComponent: DefaultError});
export default ApiPage;
